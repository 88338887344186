import React from 'react';

function About() {
  return (
    <section id="about">
      <h2>About Surfbots</h2>
      <p>Founded by a team of Ethereum native developers who've previously worked with institutional market makers such as Gotbit and Wintermute, Surfbots aims to bridge the gap between professional trading tools and the growing DeFi community. Our mission is to provide accessible, powerful, and efficient market-making solutions to empower traders and developers across various decentralized exchanges.</p>
    </section>
  );
}

export default About;
import React from 'react';

function Docs() {
  return (
    <section id="links">
      <h2>Links</h2>
      <p><a href="https://www.dexscreener.com/">Chart</a></p>
      <p>Twitter (coming soon)</p>
      <p>Discord (coming soon)</p>
      <p>Probably not gonna make a telegram tbh</p>
    </section>
  );
}

export default Docs;
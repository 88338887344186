import React from 'react';

function Footer() {
  return (
    <section>
      <p>&copy; 2024 Surfbots. All rights reserved.</p>
      <p><a href="#">Terms of Use</a></p>
      <p><a href="#">Privacy Policy</a></p>
    </section>
  );
}

export default Footer;
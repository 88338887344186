import React from 'react';
import mm from '../assets/mm.png';

function MarketMaking() {
  return (
    <section id="market-making">
      <h2>WTF is Market Making?</h2>
      <img src={mm} alt="Surfbots Logo" className="mm-logo" />
      <p>^ this</p>
      <p>Lol. Seriously though, market making is a trading strategy that involves buying and selling assets to provide liquidity to a market. Market makers earn profits by buying assets at a lower price and selling them at a higher price, and they also help to stabilize the market by providing liquidity. Seasoned devs use market making as a means to grow the treasury for their projects. Traders can use market making to maximize their extracted value by liquidating and/or accumulating positions strategically.</p>
      <p>Currently, market making in DeFi is dominated by institutions that charge ~5000 USD per month + 20% of profits on average. By eliminating middlemen and automating the entire process, Surfbots is able to cut costs tremendously while providing the same value.</p>
    </section>
  );
}

export default MarketMaking;
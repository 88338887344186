import React from 'react';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import MarketMaking from './components/MarketMaking';
import HowItWorks from './components/HowItWorks';
import Tokenomics from './components/Tokenomics';
import Docs from './components/Docs';
import Footer from './components/Footer';
import './styles.css';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Home />
        <About />
        <MarketMaking />
        <HowItWorks />
        <Tokenomics />
        <Docs />
        <Footer />
      </main>
    </div>
  );
}

export default App;
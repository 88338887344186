import React from 'react';
import logo from '../assets/surfbots_logo.png';

function Home() {
  return (
    <section id="home">
      <img src={logo} alt="Surfbots Logo" className="large-logo" />
      <h1>Surfbots ($SURF)</h1>
      <h2>Institutional-grade market making, for everyone</h2>
      <p>Surfbots leverages proprietary, high-performance technology to democratize advanced trading strategies.</p>
      <p>Powered by <a href="https://ultrasound.money" target="_blank">ultrasound.money</a>, built with <a href="https://www.rust-lang.org/" target="_blank">rust</a></p>
    </section>
  );
}

export default Home;
import React from 'react';

function HowItWorks() {
  return (
    <section id="how-it-works">
      <h2>How Surfbots Works</h2>
      <p>Surfbots is fully automated and can be used via the online dashboard or telegram bot.</p>
      <p><b>Step 1:</b> Fund Surfbots with your token and/or ETH/stables to market make with. Rather than using normal wallets, Surfbots uses smart contracts that are hardcoded to only use the funds to market make and enforces that only the user can withdraw funds. This eliminates any risk of hacks.</p>
      <p><b>Step 2:</b> Set parameters - target liquidation amounts, price ranges, and other advanced parameters.</p>
      <p><b>Step 3:</b> Sit back and watch the magic happen. Adjust parameters as you go.</p>
      <p>Surfbots is built to maximize speed and performance. It connects to a cluster of distributed ETH nodes to access mempool data, and make fast transactions via the ultrasound money relay.</p>
    </section>
  );
}

export default HowItWorks;
import React, { useState } from 'react';
import logo from '../assets/surfbots_logo_cropped.png';

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header>
      <div className="logo">
        <img src={logo} alt="Surfbots Logo" height="30" />
      </div>
      <button className={`nav-toggle ${isNavOpen ? 'active' : ''}`} onClick={toggleNav}>
        <span className="hamburger"></span>
      </button>
      <nav className={isNavOpen ? 'open' : ''}>
        <a href="#home" onClick={toggleNav}>Home</a>
        <a href="#about" onClick={toggleNav}>About</a>
        <a href="#market-making" onClick={toggleNav}>Market Making</a>
        <a href="#how-it-works" onClick={toggleNav}>How It Works</a>
        <a href="#tokenomics" onClick={toggleNav}>Tokenomics</a>
        <a href="#links" onClick={toggleNav}>Links</a>
      </nav>
    </header>
  );
}

export default Header;